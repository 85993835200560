import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Layout from "./layout";
import { createTheme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import store from "./store";

import LoginPage from "./pages/LoginPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4639CA",
      light: "#ecebfa",
    },
    secondary: {
      main: "#00ff80",
      light: "#99ffcc",
    },
    orange: {
      main: "#fa6800",
      light: "#fff0e6",
    },
    disable: {
      main: "#888888",
      light: "#f2f2f2",
    },
    white: {
      main: "#ffffff",
      light: "#dddddd",
    },
    red: {
      main: "#000000",
      light: "#ff7961",
    },
    prs: {
      main: "#6fad88",
      light: "#ecebfa",
    },
  },
});

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Layout />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

let AppComponent;

if (process.env.REACT_APP_LOCAL_INSTALLATION !== "YES") {
  // If not a local installation, apply withAuthenticationRequired
  AppComponent = withAuthenticationRequired(App);
} else {
  // If a local installation, use the component directly
  AppComponent = App;
}

export default AppComponent;
